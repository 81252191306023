<template>
  <div>
    <Header :logo="'hide'" back-title="Shopping Cart" />
    <c-box paddingTop="30px" paddingBottom="80px">
      <c-box w="100%" maxWidth="1200px" mx="auto">
        <Breadcrumb
          :items="[
            { href: '/', label: 'Beranda' },
            {
              href: '#/cart',
              label: 'Keranjang',
            },
            {
              href: '#/cart/checkout',
              label: 'Checkout',
            },
            { label: 'Pembayaran', isCurrent: true },
          ]"
        />
        <c-heading
          as="h2"
          fontSize="36px"
          fontWeight="600"
          color="black.900"
          marginBottom="40px"
        >
          Pembayaran
        </c-heading>
        <c-grid
          w="100%"
          maxWidth="1200px"
          minHeight="800px"
          boxShadow="4px 4px 50px 5px rgba(0, 0, 0, 0.05)"
          borderRadius="6px"
          h="auto"
          mx="auto"
          template-columns="repeat(1, 1fr)"
          gap="12"
        >
          <c-flex alignItems="center" justifyContent="center">Midtrans</c-flex>
        </c-grid>
      </c-box>
    </c-box>
    <Footer />
  </div>
</template>

<script>
import _ from "lodash";
import Header from "@/components/header/index.vue";
import Footer from "@/components/Footer.vue";
import Breadcrumb from "@/components/breadcrumb.vue";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "PaymentPage",
  components: {
    Header,
    Footer,
    Breadcrumb,
  },
  computed: {
    ...mapGetters({
      transactionToken: "cart/transactionToken",
    }),
    token() {
      return this.transactionToken;
    },
  },
  methods: {
    ...mapActions({
      setSuccessMessages: "clients/updateSuccessPayload",
    }),
    submitPayment() {
      let self = this;
      // Trigger snap popup. @TODO: Replace TRANSACTION_TOKEN_HERE with your transaction token
      window.snap.pay(this.token, {
        onSuccess: function (result) {
          /* You may add your own implementation here */
          console.log("sukses", result);
          self.setSuccessMessages({
            title: "Pilih Program",
            body: "Pembayaran Berhasil",
            description:
              "Pembayaran berhasil. Silahkan lakukan pengisian kuesioner <br/> untuk tahap selanjutnya",
          });
          return self.$router
            .push({
              name: "client.success",
              query: { order_id: result.order_id },
            })
            .catch();
        },
        onPending: function (result) {
          /* You may add your own implementation here */
          console.log(result);
        },
        onError: function (result) {
          /* You may add your own implementation here */
          console.log(result);
          self.$router.push({ name: "client.checkout" });
        },
        onClose: function () {
          /* You may add your own implementation here */
          self.$router.push({ name: "client.index" });
        },
      });
    },
  },
  mounted() {
    if (_.isEmpty(this.transactionToken) || !window.snap)
      return this.$router.push({ name: "client.cart" });

    this.submitPayment();
  },
};
</script>

<style></style>
