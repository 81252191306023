var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Header',{attrs:{"logo":'hide',"back-title":"Shopping Cart"}}),_c('c-box',{attrs:{"paddingTop":"30px","paddingBottom":"80px"}},[_c('c-box',{attrs:{"w":"100%","maxWidth":"1200px","mx":"auto"}},[_c('Breadcrumb',{attrs:{"items":[
          { href: '/', label: 'Beranda' },
          {
            href: '#/cart',
            label: 'Keranjang',
          },
          {
            href: '#/cart/checkout',
            label: 'Checkout',
          },
          { label: 'Pembayaran', isCurrent: true } ]}}),_c('c-heading',{attrs:{"as":"h2","fontSize":"36px","fontWeight":"600","color":"black.900","marginBottom":"40px"}},[_vm._v(" Pembayaran ")]),_c('c-grid',{attrs:{"w":"100%","maxWidth":"1200px","minHeight":"800px","boxShadow":"4px 4px 50px 5px rgba(0, 0, 0, 0.05)","borderRadius":"6px","h":"auto","mx":"auto","template-columns":"repeat(1, 1fr)","gap":"12"}},[_c('c-flex',{attrs:{"alignItems":"center","justifyContent":"center"}},[_vm._v("Midtrans")])],1)],1)],1),_c('Footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }